import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import UserPanel from '../user-panel/UserPanel';
import Button from 'devextreme-react/button';
import './Header.scss';

export default function Header() {
  return (
    <header className={'header-component'}>
      <div className={'topbar-menu'}>
        <a href='#/payments'>Платежи</a>
        <a href='#/reports'>Отчеты</a>
      </div>
      <div className={'box right-content'}>
        <Button
          className={'user-button authorization'}
          focusStateEnabled={false}
          activeStateEnabled={false}
          hoverStateEnabled={false}
          height={'100%'}
          stylingMode={'text'}
        >
          <UserPanel menuMode={'context'}/>
        </Button>
      </div>
    </header>
  )
}
