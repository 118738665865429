import {Payments, Reports} from './pages';
import {withNavigationWatcher} from './contexts/navigation';

const routes = [
  {
    path: '/payments',
    element: Payments
  },
  {
    path: '/reports',
    element: Reports
  }
];

export default routes.map(route => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path)
  };
});
