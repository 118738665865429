import React, {useEffect, useRef, useState} from 'react';
import {Button, DateBox, SelectBox} from 'devextreme-react';
import {RequiredRule, Validator,} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import {formatDateForGridExport} from 'utils/functions';
import {apiRequest} from 'api/async';
import {vars} from 'utils/variables';
import notify from 'devextreme/ui/notify';
import {exportToFile} from 'utils/export';

const {APP_CODES: {SUCCESS}} = vars

const reports = {
  'TPNReport/Acquiring': [
    'SettlementDate',
    'SettlementPeriodDate',
    'SettlementID',
    'PaymentMessageGuid',
    'OrderID',
    'DebtorName',
    'DebtorID',
    'DebtorIBAN',
    'DebtorAgentName',
    'DebtorAgentBic',
    'CreditorName',
    'CreditorID',
    'CreditorIBAN',
    'UltimateCreditorName',
    'UltimateCreditorID',
    'UltimateCreditorIBAN',
    'Amount',
    'TotalFeeAmount',
    'IssFeeAmount',
    'AcqFeeAmount',
    'IssAmountToPay',
    'AcqAmountToPay',
  ],
  'TPNReport/CityAcquiring': [
    'InsDate',
    'LastUpdate',
    'OrderID',
    'Amount',
    'FeeAmount',
    'Purpose',
    'PaymentMessageGuid',
  ],
  'TPNReport/Issuing': [
    'PayDate',
    'DebtorName',
    'DebtorID',
    'DebtorIBAN',
    'DebtorDocumentID',
    'SettlementDate',
    'SettlementPeriodDate',
    'SettlementID',
    'PaymentMessageGuid',
    'OrderID',
    'CreditorAgentName',
    'CreditorAgentBic',
    'CreditorName',
    'CreditorIBAN',
    'UltimateCreditorName',
    'UltimateCreditorID',
    'UltimateCreditorIBAN',
    'Amount',
    'FeeAmount',
    'AmountToPay',
  ]
};

const acquiringStatusList = [
  {value: {IsInternalReconciliation: 1}, name: 'прошла внутренняя сверка'},
  {value: {IsInternalReconciliation: 0}, name: 'не прошла внутренняя сверка'},
  {value: {IsExternalReconciliation: 1}, name: 'прошла внешняя сверка'},
  {value: {IsExternalReconciliation: 0}, name: 'не прошла внешняя сверка'},
  {value: 0, name: 'все платежи'},
];

const cityAcquiringStatusList = [
  {value: 0, name: 'все'},
  {value: 1, name: 'успешные'},
];

const issuingStatusList = [
  {value: {IsInternalReconciliation: 1}, name: 'прошла внутренняя сверка'},
  {value: {IsInternalReconciliation: 0}, name: 'не прошла внутренняя сверка'},
  {value: 0, name: 'все платежи'},
];

const Reports = () => {
  const validationGroupRef = useRef(null);
  const [reportType, setReportType] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [charsetType, setCharsetType] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusList, setStatusList] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    let selectedStatusList = null;

    switch (reportType) {
      case 'TPNReport/Acquiring':
        selectedStatusList = acquiringStatusList;
        break;
      case 'TPNReport/CityAcquiring':
        selectedStatusList = cityAcquiringStatusList;
        break;
      case 'TPNReport/Issuing':
        selectedStatusList = issuingStatusList;
        break;
    }

    setStatusList(selectedStatusList);
  }, [reportType]);

  useEffect(() => {
    if (reportType === 'TPNReport/CityAcquiring') {
      setStatus(1);
    }
  }, [statusList]);

  const fetchReport = async () => {
    const params = {
      FromDate: formatDateForGridExport(fromDate),
      ToDate: formatDateForGridExport(toDate),
    };

    if (!Number.isInteger(status)) {
      Object.assign(params, status);
    } else if(reportType === 'TPNReport/CityAcquiring') {
      Object.assign(params, {
        IsSuccess: status,
      });
    }

    const request = await apiRequest({
      operation: reportType,
      data: {
        Params: params,
      }
    });

    if (request.data.ResponseCode === SUCCESS) {
      return request.data.Response.Payments;
    } else {
      throw new Error(request.data.ResponseCode);
    }
  }

  const reorderFields = (data, reportType) => {
    const order = reports[reportType];
    if (!order) return data;

    return data.map(item =>
      Object.fromEntries(order.map(key => [key, item[key] ?? null]))
    );
  };

  const handleSubmit = async ({component}) => {
    const validationGroup = validationGroupRef.current.instance();
    const result = validationGroup.validate();

    if (result.isValid) {
      component.option('disabled', true);

      try {
        let data = await fetchReport();
        data = reorderFields(data, reportType);

        const fromDateString = fromDate ? fromDate.toLocaleDateString('en-CA') : '';
        const toDateString = toDate ? toDate.toLocaleDateString('en-CA') : '';

        const filename = `${reportType}_${fromDateString}_${toDateString}`;
        exportToFile(data, fileType, filename);
      } catch (error) {
        notify(error, 'error', 2000);
      } finally {
        component.option('disabled', false);
      }
    }
  }

  return (
    <div className={'reports-wrapper'}>
      <h4 className={'content-block grid-title'}>Отчеты</h4>
      <ValidationGroup ref={validationGroupRef}>
        <div className={'reports-bar'}>
          <SelectBox
            width={200}
            placeholder={'Вид отчета'}
            items={Object.keys(reports)}
            onValueChanged={({value}) => {
              setReportType(value);
            }}
          >
            <Validator>
              <RequiredRule message={'Поле обязательное'}/>
            </Validator>
          </SelectBox>
          <SelectBox
            placeholder={'Тип файла'}
            items={[
              'xlsx',
              'csv',
            ]}
            onValueChanged={({value}) => {
              setFileType(value);
            }}
          >
            <Validator>
              <RequiredRule message={'Поле обязательное'}/>
            </Validator>
          </SelectBox>
          <SelectBox
            placeholder={'Кодировка'}
            items={['win 1251']}
            onValueChanged={({value}) => {
              setCharsetType(value);
            }}
          >
            <Validator>
              <RequiredRule message={'Поле обязательное'}/>
            </Validator>
          </SelectBox>
          <SelectBox
            width={250}
            placeholder={'Статус платежей'}
            displayExpr={'name'}
            valueExpr={'value'}
            value={status}
            items={statusList}
            onValueChanged={({value}) => {
              setStatus(value);
            }}
          >
            <Validator>
              <RequiredRule message={'Поле обязательное'}/>
            </Validator>
          </SelectBox>
          <div style={{display: "flex", gap: "10px"}}>
            <DateBox
              value={fromDate}
              onValueChanged={(e) => setFromDate(e.value)}
              displayFormat="dd-MM-yyyy"
              placeholder="С"
            >
              <Validator>
                <RequiredRule message={'Поле обязательное'}/>
              </Validator>
            </DateBox>
            <DateBox
              value={toDate}
              onValueChanged={(e) => setToDate(e.value)}
              displayFormat="dd-MM-yyyy"
              placeholder="По"
              min={fromDate}
            >
              <Validator>
                <RequiredRule message={'Поле обязательное'}/>
              </Validator>
            </DateBox>
          </div>
          <Button useSubmitBehavior icon={'download'} onClick={handleSubmit}/>
        </div>
      </ValidationGroup>
    </div>
  );
}

export default Reports;