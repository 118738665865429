import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';

export const exportToFile = (data, fileType, filename = 'report') => {
  if (!data || !data.length) {
    throw new Error('No data to export');
  }

  if (fileType === 'xlsx') {
    return exportToExcel(data, filename);
  } else if (fileType === 'csv') {
    return exportToCsv(data, filename);
  }

  throw new Error('Unsupported file type');
};

const exportToExcel = (data, filename) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  saveAs(blob, `${filename}.xlsx`);
};

const exportToCsv = (data, filename) => {
  const headers = Object.keys(data[0]);
  const csvRows = [
    headers.join(','), // Header row
    ...data.map(row =>
      headers.map(header => {
        const value = row[header]?.toString() || '';
        // Escape quotes and wrap fields containing commas in quotes
        return value.includes(',') || value.includes('"')
          ? `"${value.replace(/"/g, '""')}"`
          : value;
      }).join(',')
    )
  ];

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
  saveAs(blob, `${filename}.csv`);
};